<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-xs-12 col-sm-10 col-md-10 col-sm-offset-1 col-md-offset-1">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h2>Nuova validazione</h2>
        </div>
        <form (ngSubmit)="onSubmit()" #validationForm="ngForm">
          <div class="ibox-content">
            <h2 style="padding-left: 1rem">Informazioni cliente</h2>
            <!-- CUSTOMER INFO -->
            <div class="form-group col-xs-12 col-sm-4">
              <label for="name">Nome</label>
              <input class="form-control" type="text"
              id="name"
              name="name"
              ngModel
              #name="ngModel"
              [ngModel]="dataValidation?.name"
              [disabled]="!!customerData"
              required>
            </div>
            <div class="form-group col-xs-12 col-sm-4">
              <label for="surname">Cognome</label>
              <input class="form-control" type="text"
              id="surname"
              name="surname"
              ngModel
              #surname="ngModel"
              [ngModel]="dataValidation?.surname"
              [disabled]="!!customerData"
              required>
            </div>
            <div class="form-group col-xs-12 col-sm-4">
              <label for="email">Email</label>
              <input class="form-control" type="text"
              id="email"
              name="email"
              ngModel
              #email="ngModel"
              [ngModel]="dataValidation?.email"
              [disabled]="!!customerData"
              required>
            </div>
            <div class="form-group col-xs-12 col-sm-6">
              <label for="fiscalCode">Codice fiscale</label>
              <input class="form-control" type="text"
              id="fiscalCode"
              name="fiscalCode"
              ngModel
              #fiscalCode="ngModel"
              [ngModel]="dataValidation?.fiscalCode"
              [disabled]="customerData?.fiscalCode"
              pattern="^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]{1}$"
              required>
            </div>
            <div class="form-group col-xs-12 col-sm-6">
              <label for="birthDate">Data di nascita</label>
              <input type="text"
              class="form-control"
              name="birthDate"
              id="birthDate"
              ngModel
              required
              bsDatepicker
              #bsDatepicker
              placement="bottom"
              autocomplete="off"
              [ngModel]="dataValidation?.birthDate"
              [disabled]="customerData?.birthDate"
              [bsConfig]="bsConfig">
            </div>
            <!-- PASS INFO -->
            <h2 style="display: flex; padding: 2rem 0 0 1rem;">Informazioni validazione</h2>
            <div class="form-group col-xs-12 col-sm-3">
              <label for="idPass">Tessera</label>
              <ng-select [items]="dataPasses"
                bindLabel="name"
                bindValue="id"
                [hideSelected]="true"
                [virtualScroll]="true"
                placeholder="Seleziona o cerca una tessera"
                name="idPass"
                [(ngModel)]="validationForm.idPass"
                required>
              </ng-select>
            </div>
            <div class="form-group col-xs-12 col-sm-3">
              <label for="idCustomerValidationTypology">Tipologia</label>
              <select class="form-control" type="text"
                id="idCustomerValidationTypology"
                name="idCustomerValidationTypology"
                ngModel
                idCustomerValidationTypology
                #idCustomerValidationTypology="ngModel"
                [ngModel]="dataValidation?.idCustomerValidationTypology"
                required>
                <option *ngFor="let t of dataValidationTypologies" [ngValue]="t.id">{{ t.typology }}</option>
              </select>
            </div>
            <div class="form-group col-xs-12 col-sm-6">
              <label for="validationDescription">Note</label>
              <input class="form-control" type="text"
              id="validationDescription"
              name="validationDescription"
              ngModel
              #validationDescription="ngModel"
              [ngModel]="dataValidation?.validationDescription">
            </div>
            <div class="row file-upload-row" *ngFor="let n of [].constructor(numOfFiles); let i = index">
              <div class="form-group col-xs-12 col-sm-6">
                <label for="file">Documento #{{(i + 1)}}</label>
                <input class="form-control" type="file" accept=".*"
                id="file"
                name="file"
                (change)="handleFilesInput($event.target.files[0], i)">
                <label *ngIf="excessiveFiles.includes(i)" style="color: red; position: absolute;"><small>Sono ammessi solo file di dimensione inferiore a 5 MB</small></label>
              </div>
              <div class="form-group col-xs-12 col-sm-6">
                <label for="fileDescription">Descrizione documento #{{(i + 1)}}</label>
                <input class="form-control" type="text"
                id="fileDescription"
                name="fileDescription"
                [(ngModel)]="fileDescriptions[i]"
                [ngModelOptions]="{standalone: true}">
              </div>
              <!-- Maximum of 3 files to be uploaded at the same time -->
              <em class="fa fa-plus-circle" *ngIf="i === 0; else removeAction"
                [ngClass]="{'disabled': numOfFiles >= 5}"
                (click)="changeFileQty(1)"
              ></em>
              <ng-template #removeAction>
                <em class="fa fa-minus-circle" (click)="changeFileQty(0, i)"></em>
              </ng-template>
            </div>
            <div class="form-group col-xs-12" style="display: flex; height: 3rem;">
              <label for="thirdPartyUser"></label>
              <div class="checkbox">
                <input class="form-control" type="checkbox"
                id="thirdPartyUser"
                name="thirdPartyUser"
                style="cursor: pointer;"
                [(ngModel)]="thirdPartyUser"
                [ngModelOptions]="{standalone: true}">
                <label>Richiesta per conto terzi</label>
              </div>
            </div>
            <!-- LEGAL TUTOR INFO -->
            <ng-container *ngIf="thirdPartyUser">
              <h2 style="display: flex; padding: 2rem 0 0 1rem;">Informazioni proprietario tessera</h2>
              <div class="form-group col-xs-12 col-sm-4">
                <label for="ownerFirstName">Nome</label>
                <input class="form-control" type="text"
                id="ownerFirstName"
                name="ownerFirstName"
                ngModel
                #ownerFirstName="ngModel"
                [ngModel]="dataValidation?.ownerFirstName"
                required>
              </div>
              <div class="form-group col-xs-12 col-sm-4">
                <label for="ownerLastName">Cognome</label>
                <input class="form-control" type="text"
                id="ownerLastName"
                name="ownerLastName"
                ngModel
                #ownerLastName="ngModel"
                [ngModel]="dataValidation?.ownerLastName"
                required>
              </div>
              <div class="form-group col-xs-12 col-sm-4">
                <label for="ownerFiscalCode">Codice fiscale</label>
                <input class="form-control" type="text"
                id="ownerFiscalCode"
                name="ownerFiscalCode"
                ngModel
                #ownerFiscalCode="ngModel"
                [ngModel]="dataValidation?.ownerFiscalCode"
                pattern="^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]{1}$"
                required>
              </div>
            </ng-container>
            <div class="clearfix"></div>
          </div>
          <div class="ibox-footer">
            <button class="btn btn-default" (click)="returnToList()" type="button">Annulla</button>
            <button class="btn btn-redBU" type="submit" style="float: right" [disabled]="!validationForm.valid || !!excessiveFiles.length">Salva</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>